import React from "react"
import axios from "axios"
import { Button } from "react-bootstrap"
import CareerHeader from "../components/header/careerHeader"
import firebase from "firebase/app"
import Footer from "../components/footer/footer"
import { Form } from "react-bootstrap"
import { Input } from "../components/Input/Input"
import { Link } from "gatsby"
import SEO from "../components/seo"
import * as styles from "../styles/pages/career.module.css"
import { Typeahead } from "react-bootstrap-typeahead"
import { useState } from "react"
import "react-bootstrap-typeahead/css/Typeahead.css"


const Career = () => {
  const config = {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_AUTH_DOMAIN,
    databaseURL: process.env.GATSBY_DB_URL,
    projectId: process.env.GATSBY_PROJECT_ID,
    storageBucket: process.env.GATSBY_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_MESSAGE_SENDER_ID,
    appId: process.env.GATSBY_APP_ID,
  }

  const [step, setStep] = useState(1)
  const [careerVal, setCareerVal] = useState({
    specialization: [],
    experience: "",
    stepErr: "",
    contact: {
      name: "",
      nameErr: "",
      email: "",
      emailErr: "",
      phone: "",
      phoneErr: "",
      linkedin: "",
      linkedinErr: "",
      resume: "",
      resumeErr: "",
      github: "",
      githubErr: "",
    },
  })
  const [technologies, setTechnologies] = useState([])

  const options = [
    "React",
    "Angular",
    "Node.JS",
    "HTML/CSS",
    "Selenium",
    "django",
    "Vue",
    "Python",
    "Docker",
    "Kubernetes",
    "Devops",
    "Cypress",
    "Javascript",
    "Typescript",
    "Manual testing",
    "JQuery",
    "Shell scripting",
  ]

  const HandleBackStep = () => {
    let temp = step - 1
    setStep(temp)
  }

  const HandleNextStep = ele => {
    if (ele !== "") {
      let temp = step + 1
      setCareerVal({ ...careerVal, stepErr: "" })
      setStep(temp)
    } else {
      setCareerVal({ ...careerVal, stepErr: "This field is required" })
      return null
    }
  }

  const HandleNextStepArray = ele => {
    if (ele.length !== 0) {
      let temp = step + 1
      setCareerVal({ ...careerVal, stepErr: "" })
      setStep(temp)
    } else {
      setCareerVal({
        ...careerVal,
        stepErr: "Please choose atleast one option",
      })
      return null
    }
  }

  const HandleSubmit = () => {
    if (!firebase) {
      firebase.initializeApp(config)
    }

    let quoteData = {
      email: careerVal.contact.email,
      name: careerVal.contact.name,
      designation: careerVal.specialization.join(),
      experience: careerVal.experience,
      resume: careerVal.contact.resume,
      linkedin: careerVal.contact.linkedin,
      github: careerVal.contact.github,
      languages: technologies.join(),
      number: careerVal.contact.phone,
    }

    if (validateContactForm()) {
      axios
        .post(
          "https://us-central1-yogi-r-website-forms.cloudfunctions.net/jobRequest",
          quoteData
        )
        .then(res => {
          setStep(step + 1)
        })
    }
  }

  const handleContactFormChange = e => {
    setCareerVal({
      ...careerVal,
      contact: {
        ...careerVal.contact,
        [e.target.name]: e.target.value,
        [e.target.name + "Err"]: "",
      },
    })
  }

  const validateContactForm = () => {
    const emailRegex = /^[a-z][a-z0-9+-_.]{1,}@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])$/
    const phoneRegex = /^[0-9]*$/
    let temp = careerVal

    let valid = true

    if (careerVal.contact.email === "") {
      temp.contact.emailErr = "This field is required."
      valid = false
    } else if (!emailRegex.test(careerVal.contact.email)) {
      temp.contact.emailErr = "Please enter a correct email ID"
      valid = false
    }
    if (careerVal.contact.name === "") {
      temp.contact.nameErr = "This field is required."
      valid = false
    }
    if (careerVal.contact.phone === "") {
      temp.contact.phoneErr = "This field is required."
      valid = false
    } else if (!phoneRegex.test(careerVal.contact.phone)) {
      temp.contact.phoneErr = "Please enter a valid mobile number"
      valid = false
    }
    if (careerVal.contact.linkedin === "") {
      temp.contact.linkedinErr = "This field is required."
      valid = false
    }
    if (careerVal.contact.resume === "") {
      temp.contact.resumeErr = "This field is required."
      valid = false
    }
    if (careerVal.contact.github === "") {
      temp.contact.githubErr = "This field is required."
      valid = false
    }

    setCareerVal({ ...temp })
    return valid
  }

  const getCareerForm = () => {
    switch (step) {
      case 1:
        return (
          <div className={styles.stepContainer}>
            <h3>Which area(s) do you specialize in ?</h3>
            <Form className={styles.careerForm}>
              {[
                "Front-end developer",
                "Back-end developer",
                "Full-stack developer",
                "Automation Engineer",
                "Data scientist",
                "DevOps",
              ].map(label => (
                <div key={label}>
                  <Form.Check
                    name="specialization"
                    checked={
                      careerVal.specialization.indexOf(label) !== -1
                        ? true
                        : false
                    }
                    onChange={() => {
                      let temp = careerVal.specialization
                      let index = temp.indexOf(label)
                      if (index !== -1) {
                        temp.splice(index, 1)
                        setCareerVal({
                          ...careerVal,
                          specialization: [...temp],
                        })
                      } else {
                        temp.push(label)
                        setCareerVal({
                          ...careerVal,
                          stepErr: "",
                          specialization: [...temp],
                        })
                      }
                    }}
                    className={styles.checkboxLabel}
                    type={"checkbox"}
                    id={"title-" + label}
                    label={label}
                  />
                </div>
              ))}
              <div className={styles.errContainer}>
                <p>{careerVal.stepErr}</p>
              </div>
              <div className={styles.controlsContainer}>
                <Link to="/work-with-us">
                  <Button className={styles.backBtn}>Exit</Button>
                </Link>
                <Button
                  onClick={() => {
                    HandleNextStepArray(careerVal.specialization)
                  }}
                  className={styles.nextBtn}
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
        )
      case 2:
        return (
          <div className={styles.stepContainer}>
            <h3>What are the top technologies that you are an expert in ?</h3>
            <Form className={styles.careerForm}>
              <Typeahead
                id="career-typeahead"
                allowNew
                multiple
                options={options}
                selected={technologies}
                onChange={setTechnologies}
                placeholder="Choose skill..."
              />
            </Form>
            <div className={styles.errContainer}>
              <p>{careerVal.stepErr}</p>
            </div>
            <div className={styles.controlsContainer}>
              <Button onClick={HandleBackStep} className={styles.backBtn}>
                Back
              </Button>
              <Button
                onClick={() => {
                  HandleNextStepArray(technologies)
                }}
                className={styles.nextBtn}
              >
                Next
              </Button>
            </div>
          </div>
        )
      case 3:
        return (
          <div className={styles.stepContainer}>
            <h3>How many years of experience do you have ?</h3>
            <Form className={styles.careerForm}>
              {["fresher", ">1", "1-3", "3-5", "5-8", "8+"].map(label => (
                <Form.Check
                  name="experience"
                  checked={careerVal.experience === label ? true : false}
                  onChange={() => {
                    setCareerVal({ ...careerVal, experience: label })
                  }}
                  className={styles.radioLabel}
                  type={"radio"}
                  label={label}
                  id={"career-radio-" + label}
                />
              ))}
              <div className={styles.errContainer}>
                <p>{careerVal.stepErr}</p>
              </div>
              <div className={styles.controlsContainer}>
                <Button onClick={HandleBackStep} className={styles.backBtn}>
                  Back
                </Button>
                <Button
                  onClick={() => {
                    HandleNextStep(careerVal.experience)
                  }}
                  className={styles.nextBtn}
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
        )
      case 4:
        return (
          <div className={styles.stepContainer}>
            <h3>Great, Please give your contact details</h3>
            <Form className={styles.contactForm}>
              <Input
                inpClass={styles.contactInp}
                errText={careerVal.contact.nameErr}
                onChangeCallBack={e => {
                  handleContactFormChange(e)
                }}
                value={careerVal.contact.name}
                placeholder="Full Name"
                name="name"
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Input
                    inpClass={styles.contactHalfInp}
                    errText={careerVal.contact.emailErr}
                    onChangeCallBack={e => {
                      handleContactFormChange(e)
                    }}
                    value={careerVal.contact.email}
                    type="email"
                    placeholder="Email"
                    name="email"
                  />
                </div>
                <div>
                  <Input
                    inpClass={styles.contactHalfInp}
                    errText={careerVal.contact.phoneErr}
                    onChangeCallBack={e => {
                      handleContactFormChange(e)
                    }}
                    value={careerVal.contact.phone}
                    type="phone"
                    placeholder="Phone number (without country code)"
                    name="phone"
                  />
                </div>
              </div>
              <Input
                inpClass={styles.contactInp}
                errText={careerVal.contact.linkedinErr}
                onChangeCallBack={e => {
                  handleContactFormChange(e)
                }}
                value={careerVal.contact.linkedin}
                placeholder="LinkedIn profile"
                name="linkedin"
              />
              <Input
                inpClass={styles.contactInp}
                errText={careerVal.contact.githubErr}
                onChangeCallBack={e => {
                  handleContactFormChange(e)
                }}
                value={careerVal.contact.github}
                placeholder="GitHub/ GitLab URL (Optional)"
                name="github"
              />
              <Input
                inpClass={styles.contactInp}
                errText={careerVal.contact.resumeErr}
                onChangeCallBack={e => {
                  handleContactFormChange(e)
                }}
                value={careerVal.contact.resume}
                placeholder="resume URL"
                name="resume"
              />
              <div className={styles.controlsContainer}>
                <Button onClick={HandleBackStep} className={styles.backBtn}>
                  Back
                </Button>
                <Button onClick={HandleSubmit} className={styles.nextBtn}>
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        )
      case 5:
        return (
          <div
            className={styles.stepContainer + " " + styles.thankyouContainer}
          >
            <div>
              <h3>
                Thank you,our team will get back to you in 3-4 business days.
              </h3>
            </div>
            <div>
              <Link to="/">
                <Button
                  className={styles.nextBtn}
                  style={{ marginTop: "20px" }}
                >
                  Go home
                </Button>
              </Link>
            </div>
          </div>
        )
      default:
        return (
          <div className={styles.stepContainer}>
            <h3>Unkown error.</h3>
            <Link to="/">Go home</Link>
          </div>
        )
    }
  }

  return (
    <body className={styles.body}>
      {/* <ContactBtn /> */}
      <CareerHeader progress={step * 20} />
      <SEO title="Careers @ YRT" />
      <div className={styles.careerContainer}>
        <div className={styles.formContainer}>{getCareerForm()}</div>
        <div className={styles.infoContainer}></div>
      </div>
      <Footer />
    </body>
  )
}

export default Career
